<template>
  <div>
  </div>
</template>

<script>
export default {
  created() {
    const currentPath = this.$route.path;

    const pdfFiles = {
      "/guide": { url: "https://assets.yingshinet.com/chronicles/GUIDE.pdf", name: "GUIDE.pdf" },
      "/guideV2": { url: "https://assets.yingshinet.com/chronicles/GuideV2.pdf", name: "GuideV2.pdf" }
    };

    if (pdfFiles[currentPath]) {
      const file = pdfFiles[currentPath];
      const link = document.createElement("a");
      link.href = file.url;
      link.target = "_self"; // 在当前页面打开
      link.download = file.name; // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.warn("无效路径，未匹配到PDF文件");
    }
  }
}
</script>

